import VueUeditorWrap from "vue-ueditor-wrap";
import { editorUploadUrl,request_sign} from "@/utils/const";
import { encryptCode,decryptCode } from "@/utils/function";
import API from '@/api/manager/user/userInfo';
import { nprogressStart, nprogressClose } from "@/plugins/nprogress.js";
export default {
    name: "expDetail",
    components: {
        VueUeditorWrap
    },
    data() {
        var validatePass = (rule, value, callback) => {
            const regExt = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@.#$!%*?&])[A-Za-z\d@.#$!%*?&]{8,}$/;
            if (!regExt.test(value)) 
            {
                callback(new Error('长度至少为8,包含大小写字母、数字和特殊字符'));
            }
        };
        return{
            // 上传教师证URL
            uploadpersonalcardUrl:process.env.VUE_APP_DOMAIN_URL + "/backend/public/index.php/adminUser/uploadTeacherCard",
            // 上传教师签名URL
            uploadsignUrl:process.env.VUE_APP_DOMAIN_URL + "/backend/public/index.php/adminUser/uploadTeacherSign",
            // ueditor 编辑器配置项
            myConfig: {
                // 初始容器宽度
                initialFrameWidth: "70%",
                // 初始容器高度
                initialFrameHeight: 100,
                // 你的UEditor资源存放的路径,相对于打包后的index.html
                UEDITOR_HOME_URL: "./Editor/",
                // 编辑器不自动被内容撑高
                // autoHeightEnabled: false,
                // 如果需要上传功能,找后端小伙伴要服务器接口地址
                serverUrl: editorUploadUrl,
                // 关闭自动保存
                enableAutoSave: false,
                // 自定义工具栏，需要额外选项可以参考ueditor.config.js
                toolbars: [
                [
                    "inserttable",//表格
                    "source", //源代码
                    "bold", //加粗
                    "italic", //斜体
                    "underline", //下划线
                    "strikethrough", //删除线
                    "fontborder", //字符边框
                    "blockquote", //引用
                    "selectall", //全选
                    "horizontal", //分隔线
                    "removeformat", //清除格式
                    "unlink", //取消链接
                    "deletecaption", //删除表格标题
                    "inserttitle", //插入标题
                    "cleardoc", //清空文档
                    "insertcode", //代码语言
                    "fontfamily", //字体
                    "fontsize", //字号
                    "insertimage", //多图上传
                    "link", //超链接
                    "emotion", //表情
                    "spechars", //特殊字符
                    "searchreplace", //查询替换
                    "insertvideo", //视频
                    "justifyleft", //居左对齐
                    "justifyright", //居右对齐
                    "justifycenter", //居中对齐
                    "forecolor", //字体颜色
                    "insertorderedlist", //有序列表
                    "insertunorderedlist", //无序列表
                    "imageleft", //左浮动
                    "imageright", //右浮动
                    "attachment", //附件
                    "imagecenter", //居中
                    "lineheight", //行间距
                ],
                ],
            },
            rules: {
                personal_id: [
                    { required: true, message: '请输入学号', trigger: 'blur' },
                ],
                name: [
                    { required: true, message: '请输入姓名', trigger: 'blur' },
                ],
                school_id: [
                    { required: true, message: '请选择学校', trigger: 'blur' },
                ],
                type: [
                    { required: true, message: '请选择身份', trigger: 'blur' },
                ],
                islogin: [
                    { required: true, message: '请选择状态', trigger: 'blur' },
                ],
                pc_password: [
                    { validator: validatePass, trigger: 'blur' }
                ],
            },
            AddFormData:{
                name:'',
                personal_id:'',
                school_id:'',
                type:'',
                is_login:'',
                balance:'',
                personal_card:'',
                desc:'',
                ilab_user:'',
                ilab_pwd:'',
                sign_url:'',
                pc_password:'',
                is_developer:'',
            },
            schoolList:[],
            typeList:[
                {
                    id:0,
                    name:'学生'
                },
                {
                    id:1,
                    name:'老师'
                },
                {
                    id:9,
                    name:'管理员'
                }
            ],
            statusList:[
                {
                    id:0,
                    name:'拉黑'
                },
                {
                    id:1,
                    name:'刚进入'
                },
                {
                    id:2,
                    name:'选择身份'
                },
                {
                    id:3,
                    name:'学生已填写信息'
                },
                {
                    id:4,
                    name:'学生已上传面部照片'
                },
                {
                    id:5,
                    name:'学生已上传身份证'
                },
                {
                    id:8,
                    name:'老师已填证件'
                },
                {
                    id:9,
                    name:'审核通过'
                },
            ],
            developerList:[
                {
                    id:"0",
                    name:'不是'
                },
                {
                    id:"1",
                    name:'是'
                },
            ],
            // 用户id
            user_id:'',
        }
    },
    created(){
        let self = this;
        self.getschoolList();
        if(self.$route.query.params)
        {
            let params = JSON.parse(decryptCode(this.$router.currentRoute.query.params));
            self.user_id = params.user_id
            self.getData();
        }
        
    },
    methods:{
        /**
         * @name: 获取页码数据
         */
        getData()
        {
            let self = this;
            let str = encryptCode({
                user_id:  self.user_id,
            })
            let data = {sign:request_sign,params:str}
            nprogressStart();
            self.$Loading.show();
            // 获取虚拟实验分类请求
            API.getUserInfoById(data)
                .then((result) => {
                    nprogressClose();
                    self.$Loading.hide();
                    if (result.code > 0) {
                        self.useloading = false;
                        self.schoolList.push({
                            id:result.userinfo.school_id,
                            name:result.userinfo.schoolName
                        }) 
                        result.userinfo.is_login= result.userinfo.islogin
                        for (const key in self.AddFormData) {
                            self.AddFormData[key] = result.userinfo[key];
                            self.AddFormData.pc_password = '';
                        }
                        self.$router.push('/manager/userInfo?params=' +  str);
                    }
                    else
                    {
                        self.$Tips({
                            // 消息提示内容
                            message: result.msg,
                            // 消息提示类型（success-成功,warning-警告/失败）
                            messageType: 'warning',
                            displayTime: 1500
                        })
                    }
                })
        },

        getschoolList(e)
        {
            let self=this;
            // 请求参数
            let data = {
                name : e,
                page:1,
                pagesize:10
            };
            // 获取学校列表请求
            API.getSchoolList(data)
                .then((result)=>{
                    self.schoolloading = false;
                    if(result.code > 0 )
                    {
                        self.schoolList = result.list
                    }
                    // else
                    // {
                    //     Message.error('系统错误')
                    // }
                })                
        },

        handlesignurlSuccess(res)
        {
            const self = this;
            if (res.code > 0) 
            {
                self.AddFormData.sign_url = res.data;
                // Message.success(res.msg);
                this.$Tips({
                    // 消息提示内容
                    message:res.msg,
                    // 消息提示类型（success-成功,warning-警告/失败）
                    messageType:'success',
                    displayTime:1500
                })
            } 
            
            else 
            {
                // Message.error(res.msg);
                this.$Tips({
                    // 消息提示内容
                    message:res.msg,
                    // 消息提示类型（success-成功,warning-警告/失败）
                    messageType:'warning',
                    displayTime:1500
                })
            }
        },
        /**
         * @name: 学生证上传成功
         */
        handlelogoSuccess(res)
        {
            const self = this;
            if (res.code > 0) 
            {
                self.AddFormData.personal_card = res.data;
                // Message.success(res.msg);
                this.$Tips({
                    // 消息提示内容
                    message:res.msg,
                    // 消息提示类型（success-成功,warning-警告/失败）
                    messageType:'success',
                    displayTime:1500
                })
            } 
            
            else 
            {
                // Message.error(res.msg);
                this.$Tips({
                    // 消息提示内容
                    message:res.msg,
                    // 消息提示类型（success-成功,warning-警告/失败）
                    messageType:'warning',
                    displayTime:1500
                })
            }
        },
        save()
        {
            let self = this;
            const regExt = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@.#$!%*?&])[A-Za-z\d@.#$!%*?&]{8,}$/;
            if(!self.AddFormData.name)
            {
                return self.$Tips({
                    // 消息提示内容
                    message: '请输入姓名',
                    // 消息提示类型（success-成功,warning-警告/失败）
                    messageType: 'warning',
                    displayTime: 1500
                })
            }
            if(!self.AddFormData.personal_id)
            {
                return self.$Tips({
                    // 消息提示内容
                    message: '请输入学号',
                    // 消息提示类型（success-成功,warning-警告/失败）
                    messageType: 'warning',
                    displayTime: 1500
                })
            }
            if(!self.AddFormData.school_id)
            {
                return self.$Tips({
                    // 消息提示内容
                    message: '请选择学校',
                    // 消息提示类型（success-成功,warning-警告/失败）
                    messageType: 'warning',
                    displayTime: 1500
                })
            }
            if(self.AddFormData.type === '')
            {
                return self.$Tips({
                    // 消息提示内容
                    message: '请选择身份',
                    // 消息提示类型（success-成功,warning-警告/失败）
                    messageType: 'warning',
                    displayTime: 1500
                })
            }
            if(self.AddFormData.is_login === '')
            {
                return self.$Tips({
                    // 消息提示内容
                    message: '请选择状态',
                    // 消息提示类型（success-成功,warning-警告/失败）
                    messageType: 'warning',
                    displayTime: 1500
                })
            }
            if (self.AddFormData.pc_password !== '' && !regExt.test(self.AddFormData.pc_password)) {
                return self.$Tips({
                    // 消息提示内容
                    message: '密码格式不正确',
                    // 消息提示类型（success-成功,warning-警告/失败）
                    messageType: 'warning',
                    displayTime: 1500
                })
            }
            let str = encryptCode({
                user_id: self.user_id,
                ...self.AddFormData
            })
            let params = encryptCode({
                user_id: self.user_id,
            })
            let data = {sign:request_sign,params:str}
            // 添加/修改虚拟实验
            API.updateUserInfo(data)
                .then((result) => {
                    if (result.code > 0) {
                        // self.getData();
                        self.$router.push('/manager/userInfo?params=' +  params);
                        self.getData();
                        self.$Tips({
                            // 消息提示内容
                            message: result.msg,
                            // 消息提示类型（success-成功,warning-警告/失败）
                            messageType: 'success',
                            displayTime: 1500
                        })
                    }
                    else
                    {
                        self.$Tips({
                            // 消息提示内容
                            message: result.msg,
                            // 消息提示类型（success-成功,warning-警告/失败）
                            messageType: 'warning',
                            displayTime: 1500
                        })
                    }
                })
        },
    }
}