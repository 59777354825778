<template>
    <div class="userInfo">
      <el-card>
        <div class="page">
          <el-form :rules="rules" :model="AddFormData" label-width="150px" label-position="left">
            <el-form-item label="姓名" prop="name">
              <el-input clearable v-model="AddFormData.name"></el-input>
            </el-form-item>
            <el-form-item label="学号" prop="personal_id">
              <el-input clearable v-model="AddFormData.personal_id"></el-input>
            </el-form-item>
            <el-form-item label="学校" prop="school_id">
              <el-select
                  v-model="AddFormData.school_id"
                  filterable
                  remote
                  placeholder="请搜索选择学校"
                  :remote-method="getschoolList">
                  <el-option
                      v-for="item in schoolList"
                      :key="item.id"
                      :label="item.name"
                      :value="item.id">
                  </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="身份" prop="type">
              <el-select  
                      v-model="AddFormData.type" 
                      placeholder="请选择身份"
                      class="" >
                    <el-option
                      v-for="item in typeList"
                      :label="item.name"
                      :value="item.id"
                    ></el-option>
                  </el-select>
            </el-form-item>
            <el-form-item label="状态" prop="islogin">
              <el-select  
                      v-model="AddFormData.is_login" 
                      placeholder="请选择状态"
                      class="" >
                    <el-option
                      v-for="item in statusList"
                      :label="item.name"
                      :value="item.id"
                    ></el-option>
                  </el-select>
            </el-form-item>
            <el-form-item label="账户积分" prop="balance">
              <el-input clearable v-model="AddFormData.balance"></el-input>
            </el-form-item>
            <el-form-item label="教师证">
              <el-upload
                :action="uploadpersonalcardUrl"
                :show-file-list="false"
                :on-success="handlelogoSuccess">
                <img v-if="AddFormData.personal_card" :src="AddFormData.personal_card" class="avatar">
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
            </el-form-item>
            <el-form-item label="网页平台密码" prop="pc_password">
              <el-input show-password clearable v-model="AddFormData.pc_password"></el-input>
            </el-form-item>
            <el-form-item label="备注" prop="desc">
              <el-input clearable type="textarea" :autosize="{ minRows: 4, maxRows: 8}" v-model="AddFormData.desc"></el-input>
            </el-form-item>
            <el-form-item label="ilab用户名" prop="ilab_user">
              <el-input clearable v-model="AddFormData.ilab_user"></el-input>
            </el-form-item>
            <el-form-item label="ilab密码" prop="ilab_pwd">
              <el-input show-password clearable v-model="AddFormData.ilab_pwd"></el-input>
            </el-form-item>
            <el-form-item label="教师签名图片" prop="sign_url">
              <el-upload
                :action="uploadsignUrl"
                :show-file-list="false"
                :on-success="handlesignurlSuccess">
                <img v-if="AddFormData.sign_url" :src="AddFormData.sign_url" class="avatar">
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
            </el-form-item>
            <el-form-item label="是否开发者" prop="is_developer">
              <el-select  
                      v-model="AddFormData.is_developer" 
                      placeholder="请选择是否是开发者"
                      class="" >
                    <el-option
                      v-for="item in developerList"
                      :label="item.name"
                      :value="item.id"
                    ></el-option>
                  </el-select>
            </el-form-item>
          </el-form>
        </div>
      </el-card>
      <div class="save">
        <!-- <el-button type="primary" @click="Back">返回</el-button> -->
        <el-button class="themeButton" @click="save">保存</el-button>
      </div>
    </div>
  </template>
<style lang="scss">
// .userInfo .el-upload-list--picture-card .el-upload-list__item-thumbnail{
//   width: 100%;
//   height: 13.5vw;
// }
.userInfo .el-upload-list--picture-card .el-upload-list__item{
  width: 30%;
  height: 15vw;
  overflow: hidden;
}
.userInfo .el-upload-list--picture-card .el-upload-list__item img{
  width: 100%;
  // height: 100%;
}
</style>
  <style lang="scss" scoped>
  @import "@/assets/css/manager/user/userInfo.scss";
  </style>
  <script>
  import userInfo from "@/assets/js/manager/user/userInfo.js";
  export default {
    ...userInfo,
  };
  </script>